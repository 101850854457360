import React from 'react';
import CardsDuvidas from '../InfoDuvidas/Informacoesuvidas';
import './duvidas.scss';

const DuvidasFrequentes = () => {

    const InfoDuvidas = {
        TituloPage: 'Tire suas principais dúvidas',
        subTitulo: 'Duvidas Frequentes',
        
        TitleCard: 'Vocês desmontam a máquina para recuperar os componentes?',
        TitleCard2: 'Quanto de pressão aguenta uma gaxeta?',
        TitleCard3: 'Qual a diferença entre gaxeta para haste e para camisa?',
        TitleCard4: 'Vocês comercializam bomba, comando e motores hidráulicos, ou só recuperam?',
        TitleCard5: 'Qual a vantagem da manutenção preventiva?',
        TitleCard6: 'Vocês vendem vedação para a linha automotiva?',
        TitleCard7: 'Trabalham com vedação para torneira, descarga e registro?',
        TitleCard8: 'Se eu levar meu êmbolo e tampa com as vedações montadas, vocês conseguem trocar na hora?',
        TitleCard9: 'Os serviços são testados antes da entrega?',
        TitleCard10: 'Trabalham com peças pneumáticas, cordão nitrílico ou manta de borracha?',
        TitleCard11: 'Qual a diferença entre O ‘Ring de 70 Shore e O ‘Ring de 90 Shore?',
        TitleCard12: 'Trabalham com selo mecânico, vedação para macaco, paleteira e jacaré?',
        TitleCard13: 'Um O ‘Ring em Viton suporta quantos graus?',
        TitleCard14: 'Tem vedador líquido?',
        TitleCard15: 'Como faço cadastro da minha empresa?',
        TitleCard16: 'Vocês fazem vedação sob medida?',
        TitleCard17: ' Vendem para consumidor final? Tem mínimo de quantidade?',


        DescribeCard: 'Não. Nós não realizamos o serviço “de campo”. É necessário que o cliente nos traga o componente isolado (pistão, bomba, comando...), para podermos prosseguir com a avaliação da manutenção.',
        DescribeCard2: 'As gaxetas indicadas para atuarem em equipamentos que exerçam pressão, são as fabricadas em Poliuretano. Sua capacidade de atuação é de 7.000 p.s.i., ou 480 bar. Caso seu equipamento exerça mais pressão, podemos analisar o sistema de vedação mais indicado.',
        DescribeCard3: 'As gaxetas para haste possuem uma segunda lábia interna que intensifica a vedação. Esses modelos são de uso exclusivo para a tampa. As gaxetas para camisa, são tidas como “universais” pois podem ser usadas tanto na tampa quanto no êmbolo. O modelo de gaxeta utilizada é o tipo B, ou seja, possui um chanfro no lábio que possibilita o alojamento de um anel o ‘Ring, propiciando aumento na pré-carga e tornando a vedação estável.',
        DescribeCard4: 'Nós trabalhamos com a comercialização de produtos novos e a recuperação desses componentes.',
        DescribeCard5: 'Além de evitar grandes dores de cabeça, a manutenção preventiva ajuda a prolongar a vida útil do seu equipamento, reduz a deterioração e faz com que sintomas não virem grandes problemas. Estamos a disposição para a manutenção do seu equipamento.',
        DescribeCard6: 'Especificamente não, mas muitas medidas de retentores automotivos coincidem com os que comercializamos. Para vedações para freio, transmissão e caixa de câmbio, conseguimos fazer adaptações, mas há diferença de medidas com a nossa padronagem.',
        DescribeCard7: 'Não. Nossas vedações são para a linha industrial e para máquinas pesadas. Algumas vezes conseguimos fazer adaptações, mas não são especificas para a linha hidráulica residencial.',
        DescribeCard8: 'Vai depender da nossa demanda de serviço, mas geralmente conseguimos atender essa urgência com excelência.',
        DescribeCard9: 'Sim! Todos os nossos serviços passam por teste de funcionalidade e capacidade de pressão.',
        DescribeCard10: 'Infelizmente não. Não atendemos essa linha de vedação.',
        DescribeCard11: 'A diferença está na dureza do anel. Quanto maior a dureza, maior a resistência. Indicamos a aplicação de anéis de 90 Shore em equipamentos que demandem alta pressão.',
        DescribeCard12: 'Infelizmente não. Não comercializamos essa linha de vedação.',
        DescribeCard13: 'Os anéis em Viton (ou Elastômero de Flúor-Carbono FKM) tem indicação de aplicação para equipamentos que exerçam alta temperatura. Sua capacidade de resistência é de -25°C à 220°C. Anéis em Viton são vendidos sob encomenda.',
        DescribeCard14: 'Infelizmente não. Não comercializamos essa linha de vedação.',
        DescribeCard15: 'Você pode mandar os seus dados cadastrais via WhatsApp, na opção FINANCEIRO ou por e-mail no financeiro@ciadavedacao.com.br',
        DescribeCard16: 'Sim! Usinamos conforme a necessidade, seja em questão de material ou medidas fora de padrão.',
    }

    return (
        <>
            <section className='duvidas-wrapper'>
                <main className='duvidas-container-top'>
                    <h5>{`${InfoDuvidas.subTitulo}`}</h5>
                    <h2>{`${InfoDuvidas.TituloPage}`}</h2>
                </main>
                <div className='duvidas-container'>
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard}`} Descricao={`${InfoDuvidas.DescribeCard}`} />
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard2}`} Descricao={`${InfoDuvidas.DescribeCard2}`} />
                </div>
                <div className='duvidas-container'>
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard3}`} Descricao={`${InfoDuvidas.DescribeCard3}`} />
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard4}`} Descricao={`${InfoDuvidas.DescribeCard4}`} />
                </div>
                <div className='duvidas-container'>
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard5}`} Descricao={`${InfoDuvidas.DescribeCard5}`} />
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard6}`} Descricao={`${InfoDuvidas.DescribeCard6}`} />
                </div>
                <div className='duvidas-container'>
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard7}`} Descricao={`${InfoDuvidas.DescribeCard7}`} />
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard8}`} Descricao={`${InfoDuvidas.DescribeCard8}`} />
                </div>
                <div className='duvidas-container'>
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard9}`} Descricao={`${InfoDuvidas.DescribeCard9}`} />
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard10}`} Descricao={`${InfoDuvidas.DescribeCard10}`} />
                </div>
                <div className='duvidas-container'>
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard11}`} Descricao={`${InfoDuvidas.DescribeCard11}`} />
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard12}`} Descricao={`${InfoDuvidas.DescribeCard12}`} />
                </div>
                <div className='duvidas-container'>
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard13}`} Descricao={`${InfoDuvidas.DescribeCard13}`} />
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard14}`} Descricao={`${InfoDuvidas.DescribeCard14}`} />
                </div>
                <div className='duvidas-container'>
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard15}`} Descricao={`${InfoDuvidas.DescribeCard15}`} />
                    <CardsDuvidas TituloCard={`${InfoDuvidas.TitleCard16}`} Descricao={`${InfoDuvidas.DescribeCard16}`} />
                </div>
            </section>
        </>
    )
}

export default DuvidasFrequentes;