import React from 'react';

import Head from '../Components/seo/Seo'
import Cabecalho from '../Components/header/Header';
import DuvidasFrequentes from '../Components/SessaoDuvidas/Duvidas';
import Footer from '../Components/footer/Rodape';

const DuvidasFrequentesPage = () => {

    const InfoDuvidasFrequentes = {
        TituloSeo: 'Companhia da Vedação - Duvidas Frequentes',
    }
    return (
        <>
            <Head Titulo={`${InfoDuvidasFrequentes.TituloSeo}`}/>
            <Cabecalho LinkName={'Duvidas'}/>
            <DuvidasFrequentes/>
            <Footer/> 
        </>
    )
}

export default DuvidasFrequentesPage;