import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './infoDuvidas.scss';

const CardsDuvidas = ({TituloCard, Descricao }) => {

    return (
        <>
            <div className='InfoDuvidas-wrapper'>
                <div className='duvidas-image-wrapper'>
                    <StaticImage className='duvidas-image' src="../../images/DuvidasIcon.webp" placeholder='blurred' alt="Companhia da Vedação"/>
                </div>
                <div className='InfoDuvidas-text-wrapper'>
                    <h4>{TituloCard}</h4>
                    <p>{Descricao}</p>
                </div>
            </div>
        </>
    )
}

export default CardsDuvidas;